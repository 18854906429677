import { Swiper, SwiperSlide } from 'swiper/react';
import { CardType } from '../../types';
import { Pagination } from 'swiper';

import 'swiper/css';
import 'swiper/css/pagination';  
import'./Cards.scss';

const Cards = (props:CardType) => {
    return (
        
        <article className='card'>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                modules={[Pagination]}
                allowTouchMove={false}
                pagination={{ clickable: true }}
                >
                {props.images.map( (image, index) =>                     
                    <SwiperSlide key={index}>
                        <div className="image-slide"  style={{ backgroundImage: `url(photos/${props.folder}/${image.filename})` }}>
                            {image.filedescription && <div className="image-legend">{image.filedescription}</div>}
                        </div>
                        { index > 0 ? <div className="swiper-pagination"></div> : null}
                    </SwiperSlide>
                )}
            </Swiper>
            <div className="card-info">
                <h1>{props.title}</h1>
                <span className="line"></span>
                <h2>{props.subtitle}</h2>
                <ul>
                    {props.description.map( (element) => 
                        element.split(', ').map( (e:any, index:number) => 
                        <li key={index}>{e}</li>
                        ))
                    }
                </ul>
                {props.url && <a href={props.url} target="_blank" rel="noreferrer">Link</a>}
            </div>
        </article>
    )
}

export default Cards