import { motion } from "framer-motion"
import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"
import { PagesNamesEnum } from "../../types"

import './Team.scss'

const Team = () => {

  document.title = PagesNamesEnum.TEAM
  
  const motionContainer = {
    hidden: { opacity: 0, y:40 },
    show: {
      opacity: 1,
      y:0,
      transition: {
        type: 'linear',
        duration: 1.5,
        staggerChildren: .5
      }
    }
  }
  
  const motionItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  return (
    <div className="container">
      <Header />
      <motion.div className="team-container"
          variants={motionContainer}
          initial="hidden"
          animate="show">

        <motion.div className="team-member" variants={motionItem}>
          <div className="team-member-left">
            <h1>JULIETTE JACOVIDIS, FOUNDER</h1>
            <p>Throughout her career, communications expert Juliette Jacovidis has gained deep experience working alongside global leaders in the beauty, lifestyle, fashion and culture sectors. Her work within both senior agency and in-house roles is diverse, but with a common thread: diving into complex projects and emerging with a proven track record of consistent results.</p>
            <p>She prides herself on her dedication to her clients, her thoroughness and her proactive mindset, anticipating issues before they arise. Thanks to her multi-national up-bringing and fluency in four languages, she is a true culture-hopper, navigating global projects with the support of her international network comprised of likeminded freelance partners.</p>
          </div>
          <div className="team-member-right">
            <img src="photos/team/Juliette.jpg" alt="Juliette" />
          </div>
        </motion.div>

        <motion.div className="team-member" variants={motionItem}>
          <div className="team-member-left">
            <h1>MELANIE JAPAUD, PARTNER</h1>
            <p>Mélanie Japaud specializes in media relations. She regularly partners with Juliette. Her work consists of supporting companies, decision-makers and institutions at each stage of their strategic thinking in terms of communications, media strategy and editorial content.</p>
            <p>Mélanie masters Corporate and General Public, BtoB and BtoC issues, across regional, national and international, in various fields ranging from the food industry to new technologies, from sport to health, through luxury and tourism.</p>
          </div>
          <div className="team-member-right">
            <img src="photos/team/Melanie.jpg" alt="Mélanie" />
          </div>
        </motion.div>

        <motion.div className="team-member" variants={motionItem}>
          <div className="team-member-left">
            <h1>VICTORIA DAUMESNIL, PARTNER</h1>
            <p>Victoria has been working as a press officer for several years, first in broadcast where she started out and then in agency. Over the past years, she has worked for various companies in different sectors. After two years in Canada (2018), working for a Montreal agency as a public relations consultant, Victoria returned to France in 2020 and now assists lifestyle brands in the operational implementation of their press relations strategies on the French and international markets.</p>
          </div>
        </motion.div>

      </motion.div>
      <Footer/>
    </div>
  )
}

export default Team