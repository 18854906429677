import './Policy.scss'

const Policy = () => {
  return (
    <section className='policy'>
      <div className="legales">
      <h1>Mentions légales</h1>

      <p><span>Jay Communication</span> est une SARL dont le capital social est de 10000 &euro; -  TVA intracommunautaire : FR19821118841</p>
      <p>Le présent site est édité par : Juliette Jocovidis, SARL ayant pour numéro de SIRET 821 118 841 00013 et qui est domiciliée au 15 rue Marc Seguin, 75018 Paris</p>
      <p>Responsable de la publication : Juliette Jacovidis, joignable par téléphone au +33 6.48.49.83.15 et par email juliette.jacovidis@jaycommunication.com</p>
      <p>Réalisation du site internet : <a href="https://sisyphe.space">Sisyphe Digital</a></p>
      <p>Hébergeur du site internet : <a href="https://www.namecheap.com/">Namecheap, Inc.</a>, 4600 East Washington Street, Suite 305, Phoenix, AZ 85034 -  USA</p>

      <h2>Propriété intellectuelle et contrefaçons</h2>
      
      <p>L’entreprise Jay Communication est propriétaire des droits de propriété intellectuelle ou détient les droits d’usage sur tous les éléments accessibles sur le site, notamment : les textes, les images, les graphismes, le logo, les icônes,…</p>
      <p>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de l’entreprise Jay Communication.</p>
      <p>Toute exploitation non autorisée du site ou d’un quelconque élément qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>

      <h2>Cookies et traceurs</h2>
      
      <p>Le site internet <a href="http://www.jaycommunication.com/">http://www.jaycommunication.com/</a> possède un système de mesure d’audience.</p>
      <p>En application de la directive européenne dite « paquet télécom », les internautes doivent être informés et donner leur consentement préalablement à l’insertion de traceurs (plus couramment appelés « cookies »). Les internautes doivent disposer d’une possibilité de choisir de ne pas être tracés lorsqu’ils visitent un site ou utilisent une application. Les éditeurs de sites internet ont donc l’obligation de solliciter au préalable le consentement des utilisateurs.</p>
      <p>Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains services. L’internaute peut toutefois configurer son navigateur internet pour refuser l’installation des cookies.</p>

      <h2>Mesure d’audience</h2>
      
      <p>Les cookies de mesure d’audience sont de petits fichiers qui permettent de connaitre et d’analyser les statistiques de trafic sur le site internet : les pages visitées, le nombre de visites, le taux de rebond, la provenance des visites,… Les cookies de mesure d’audience sont totalement anonymes.</p>
      <p>Sur ce site, c’est la solution Google Analytics qui est utilisée pour mesurer l’audience.</p>
      </div>
  </section>
  )
}

export default Policy