import { useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/svg/logo.svg';

import './Header.scss'

const Header = () => {
	let location = useLocation().pathname
	return (
		<header>
			<div className="navigation">
				<div className="logo"><Logo /></div>
				<nav>
						<ul>
								<li><a className={location === '/' ? 'current' : undefined} href="/">about</a></li>
								<li><a className={location === '/clients' ? 'current' : undefined} href="/clients">clients</a></li>
								<li><a className={location === '/team' ? 'current' : undefined} href="/team">team</a></li>
								<li><a className={location === '/contact' ? 'current' : undefined} href="/contact">contact</a></li>
						</ul>
				</nav>
			</div>
		</header>
	)
}

export default Header