import imgMap from '../../assets/png/map_jay_comm.png'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import { PagesNamesEnum } from '../../types'

import './Contact.scss'

const Contact = () => {
  document.title = PagesNamesEnum.CONTACT
  
  return (
    <div className="container">
      <Header />
      <section className='contact'>
            <div className="contact">

                <div className="contact-bottom">

                    <div className="contact-bottom-left">
                        <p>Jay Communication</p>
                        <p>15 rue Marc Seguin</p>
                        <p>75018 Paris</p>

                        <a className="contact-map" href="https://www.google.com/maps/place/15+Rue+Marc+Seguin,+75018+Paris/data=!4m2!3m1!1s0x47e66e7c1a98b87b:0xeb29ccac3bfa66af?sa=X&ved=2ahUKEwjYmJeki9v7AhUATaQEHVJiAVUQ8gF6BAgIEAE" target="_blank" rel="noopener noreferrer">Find us on the map</a>
                        <h5>+33 6 48.49.83.15</h5>
                        <a href="mailto:juliette.jacovidis@jaycommunication.com" rel="noopener noreferrer">Email</a>
                    </div>

                    <div className="contact-bottom-right">
                        <a className="contact-map" href="https://www.google.com/maps/place/15+Rue+Marc+Seguin,+75018+Paris/data=!4m2!3m1!1s0x47e66e7c1a98b87b:0xeb29ccac3bfa66af?sa=X&ved=2ahUKEwjYmJeki9v7AhUATaQEHVJiAVUQ8gF6BAgIEAE" target="_blank" rel="noopener noreferrer">
                            <img src={imgMap} alt="jay-communication map"/>
                        </a>
                    </div>
                    
                </div>

            </div>
      </section>
      <Footer />
    </div>
  )
}

export default Contact