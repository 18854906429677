import './Footer.scss'

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2022 - jay-communication - <span><a href="/policy">info</a></span></p>
    </footer>
  )
}

export default Footer;