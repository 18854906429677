export enum PagesNamesEnum {
  HOME = 'JayCommunication - About',
  CLIENTS = 'JayCommunication - Clients',
  TEAM = 'JayCommunication - Team',
  CONTACT = 'JayCommunication - Contact',
}


// clients
export type ClientType = {
  id?: number
  title : string
  subtitle : string
  description: string[]
  medias: {
    foldername: string
    images : ClientImageType[]
  }
  externalurl ?: string
}

export type DataClientType = {
  client : ClientType[]
}

export type ClientImageType = {
  fileid ?: number | string
  filename: string
  filedescription ?: string
}

export type CardType = {
  title : string
  subtitle : string
  description: string[]
  folder : string
  images : ClientImageType[]
  url?: string
}