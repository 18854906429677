import { motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import { PagesNamesEnum } from '../../types'

import './Home.scss'

type DataAboutType = {
  about : {
    text : string
  }
}

const Home = () => {
  document.title = PagesNamesEnum.HOME
 
  const [error, setError] = useState<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [aboutText, setAboutText] = useState<DataAboutType>()

  useEffect(() => {   
    fetch("data/about.json")
    .then(res => res.json())
    .then(
      (result) => {
        setAboutText(result)
        setIsLoaded(true)
      },
      (error) => {
        setError(error)
      }
    )
  }, [setAboutText, setIsLoaded])
  
  if( isLoaded) {
    return(
      <div className="container">
        <Header />
        <section className="about-illustration">
          <motion.div 
            className="about"
            initial={{ opacity: 0, y:20}}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.75,duration: 0.75 }}
            >
            <h3>{aboutText?.about.text[0]}</h3>
            <h3>{aboutText?.about.text[1]}</h3>
            <h3>{aboutText?.about.text[2]}</h3>
          </motion.div>
        </section>
        <Footer/>
      </div>
      
    )
  }
  else if ( error ) {
    return <div><code>Loading Error</code></div>
  }
  else {
    return null
  }
}

export default Home