import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Home from "./pages/Home/Home"
import Clients from "./pages/Clients/Clients"
import Team from "./pages/Team/Team"
import Contact from "./pages/Contact/Contact"
import Policy from "./pages/Policy/Policy"
import NotFound from "./pages/NotFound/NotFound"

export function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/clients" element={<Clients />} />
        <Route path="/team" element={<Team />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}