import { useEffect, useState } from 'react'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Cards from '../../components/Cards/Cards'
import { PagesNamesEnum, ClientType, DataClientType } from '../../types'
import { motion } from 'framer-motion'

import './Clients.scss'

const Clients = () => {
  document.title = PagesNamesEnum.CLIENTS
 
  const [error, setError] = useState<any>()
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const [clients, setClients] = useState<DataClientType>()
  const motionContainer = {
    hidden: { opacity: 0, y:40 },
    show: {
      opacity: 1,
      y:0,
      transition: {
        type: 'linear',
        duration: 1.25,
        staggerChildren: .35
      }
    }
  }
  
  const motionItem = {
    hidden: { opacity: 0 },
    show: { opacity: 1 }
  }

  useEffect(() => {   
    fetch("data/clients.json")
    .then(res => res.json())
    .then(
      (result) => {
        setClients(result)
        setIsLoaded(true)
      },
      (error) => {
        setError(error)
      }
    )
  }, [setIsLoaded])
  
  if( isLoaded && clients ) {
    return(
      <div className="container">
        <Header />
        <motion.div 
          className="cards-container"
          variants={motionContainer}
          initial="hidden"
          animate="show">
          {clients.client.map( (client:ClientType, index:number) => 
            <motion.div key={index} variants={motionItem}>
              <Cards 
                  key={index}
                  title={client.title}
                  subtitle={client.subtitle}
                  description={client.description} 
                  folder={client.medias.foldername} 
                  images={client.medias.images} 
                  url={client.externalurl}                       
              />
            </motion.div>
          )}
                
        </motion.div>    
        <Footer/>
      </div>
      
    )
  }
  else if ( error ) {
    return <div><code>Loading Error</code></div>
  }
  else {
    return null
  }
}

export default Clients