import Footer from "../../components/Footer/Footer"
import Header from "../../components/Header/Header"

const NotFound = () => {
  return(
    <div className="container">
      <Header />
      <h2 style={{padding: "20px"}}>Page 404</h2>
      <Footer />
    </div>
  )
}
export default NotFound